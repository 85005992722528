<template>
  <div class="p-grid crud-demo">
    <Loader :message="loadingMessage" v-model="loading" />
    <Dialog v-model:visible="modalProveedor" :closable="true" :style="{ width: '75vw' }" :modal="true">
      <SupplierUI @update="supplierUpdated" @save="supplierSaved" :id="id_supplier" :invoice_issuing_entity="invoice_issuing_entity" />
  </Dialog>
    <Dialog v-model:visible="supplierNotCreatedModel" modal :closable="false" header="Proveedor" :style="{ width: '50vw' }">
      <p>La factura {{entity.uuid}} tiene un proveedor que no esta dado de alta en el sistema (<strong>{{ entity.issuing_rfc }}</strong>)</p>
      <p>¿Desea darlo de alta?</p>
      <div class="grid p-fluid">
        <div class="col-6">
          <Button label="No" severity="danger" @click="supplierNotCreatedModel = false"></Button>
        </div>
        <div class="col-6">
          <Button label="Si" @click="openProveedor"></Button>
        </div>
      </div>
    </Dialog>
    <Helper v-model="invoiceHelper" header="Buscar Factura" :headers="invoiceHelperHeaders" :rows="invoices" @selected="selectInvoice" />
    <Helper v-model="supplierHelper" header="Buscar Proveedor" :headers="supplierHeaders" :rows="suppliers" @selected="selectSupplier" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <h2>Factura por Pagar</h2>
        <BasicFormToolbar
        @new="refresh" @search="invoiceHelper.visible = true" 
        :actions="entity.id ? ['new'] : ['new']" :importLabel="'Importar desde XML'"
        :uploadURL="uploadURL"
        @uploaded="importedXML"
        :importAccept="'text/xml'">
        <template #custom-left>
          <Button label="Provisionar" icon="pi pi-wallet" class="p-button-info mr-2" @click="provision" />
          <Button label="Cancelar Provision" icon="pi pi-times-circle" class="p-button-danger mr-2" @click="cancel_provision" />
       </template>
      </BasicFormToolbar>
        <div class="grid">
          <div class="col-12">
            <Fieldset :toggleable="true" legend="Informacion General">
              <div class="p-fluid formgrid grid">
                <FormInputText label="Factura" wrapperClass="field col-4" v-model="entity.invoice_name" :search="true" @search="invoiceHelper.visible = true"  /> 
                <Status :image="status.image" :severity="status.severity" :status="status.status" />
                <div class="col-2">
                  <FileIcon width="3.5rem" extension="xml" @click="downloadXML" />
                  <FileIcon width="3.5rem" extension="pdf" @click="downloadPDF"/>
                </div>
                <FormCalendar wrapperClass="field col-2" label="Fecha" v-model="entity.date" :validation="validate.validations.date"/>
                <FormCalendar wrapperClass="field col-2" label="Fecha Recepcion" v-model="entity.reception_date" :validation="validate.validations.date"/>
                <FormCalendar wrapperClass="field col-2" label="Fecha Vencimiento" v-model="entity.expiration_date" :validation="validate.validations.date"/>
                <FormInputText :readonly="true" v-model="entity.supplier_name" label="Proveedor" wrapperClass="field col-4" :search="true" @search="supplierHelper.visible = true" 
                  :view="true" /> 
                  <FormDropdown label="Condicion de Pago" wrapperClass="field col-2" v-model="entity.id_payment_condition" :options="payment_conditions" :optionValue="'id'" :optionLabel="'name'" ></FormDropdown>
             
                <FormDropdownComplex :labelInOption="'c_FormaPago - Descripcion'" :labelInValue="'c_FormaPago - Descripcion'" :wrapperClass="'field col-2'" label="Forma de Pago" v-model="entity.payment_form" :options="c_FormaPago" optionLabel="c_FormaPago" optionValue="c_FormaPago" />
                <FormDropdownComplex :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda'" :wrapperClass="'field col-2'" label="Moneda" v-model="entity.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" />
                <FormInputNumber label="T.C." wrapperClass="field col-2" v-model="entity.exchange_rate" :mode="'currency'" /> 
                <FormInputNumber :tooltip="'Total en moneda nacional es ' + totalMXN" label="Total" wrapperClass="field col-3" v-model="entity.total" :mode="'currency'" /> 
                <!-- <FormInputNumber :readonly="true" label="Total MXN" wrapperClass="field col-3" v-model="totalMXN" :mode="'currency'" />  -->
                <FormInputNumber :readonly="true" label="Saldo" wrapperClass="field col-3" v-model="entity.balance" :mode="'currency'" /> 
                <FormInputText label="Poliza" wrapperClass="field col" /> 
                <FormInputText label="UUID" wrapperClass="field col-4" v-model="entity.uuid" /> 
             
              </div>
            </Fieldset>
          </div>
          <div class="col-12">
            <Fieldset :toggleable="true" :collapsed="false" legend="Conceptos">
              <div class="p-fluid formgrid grid">
                  <div class="col-12">
                    <BasicDatatable :headerVisible="false"
                    :loading="loadingDatatable"
                    :headers="conceptHeaders"
                    :rows="entity.concepts"
                    :expandable="true"
                    :rowsExpandable="'taxes_list'"
                    :headersExpandable="taxesHeaders"
                    @cell-editing-complete="updateCell"
                    ></BasicDatatable>
                  </div>
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import Session from "../../../mixins/sessionMixin";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";

import Helper from "../../../components/general/HelperDialog.vue";

import { DTPInvoice } from "../../../models/cxp/DTPInvoice";
import { fillObject, HeaderGrid, satCatalogo, sleep , Toast, ErrorToast} from '../../../utilities/General';
import { Supplier } from '../../../models/cxp/Supplier';
import { PaymentCondition } from '../../../models/cxp/PaymentCondition';
import FileIcon from "../../../components/general/FileIcon.vue";
import { HEADER_TYPES } from '../../../utilities/HEADER_TYPES';
import SupplierUI from "../Catalogos/Supplier.vue";
import { AccountingLedgerAccount } from "../../../models/contabilidad/AccountingLedgerAccount";
import { AccountingCostCenter } from '../../../models/contabilidad/AccountingCostCenter';
import { DTPConcept } from '../../../models/cxp/DTPConcept';
import Status from "../../../components/general/Status.vue";

export default {
  mixins: [Session],
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      id_supplier: null,
      id_editing: null,
      invoice_issuing_entity: null,
      uploadURL: null,
      selected: [],
      invoiceHelper: {
          visible: false,
      },
      invoices: [],
      supplierHelper: {
                visible: false,
            },
      taxesHeaders:[
        new HeaderGrid("Tipo", "type"),
        new HeaderGrid("Impuesto", "tax_name", { type:"upper", formula: "search_concat", expression: { search: "find", concat: "c_Impuestos + { - } + Descripcion"}, data:
        [
          { c_Impuestos: '001', Descripcion: 'ISR' },
          { c_Impuestos: '002', Descripcion: 'IVA' },
          { c_Impuestos: '003', Descripcion: 'IEPS' },
        ], data_key: "c_Impuestos", data_search_key: "tax"}),
        new HeaderGrid("Tasa", "rate_or_fee", { type: "percentage-decimal"}),
        new HeaderGrid("Importe", "amount", { type:"currency" }),
        new HeaderGrid("Cuenta Contable", "cuenta", { type:"upper", formula: "search_concat", expression: { search: "find", concat: "id_key + { } + name"}, data: [], data_key: "id", data_search_key: "id_accounting_ledger_account"}),
      ],
      conceptHeaders: [
        new HeaderGrid("Concepto", "dtp_concept", { type: "upper", formula: "search", expression: "find", data: [], data_key: "id", data_search_key: "id_concept", data_value: "name"}),
        new HeaderGrid("Description", "description"),
        new HeaderGrid("Cuenta Contable", "cuenta", { type:"upper", formula: "search_concat", expression: { search: "find", concat: "id_key + { } + name"}, data: [], data_key: "id", data_search_key: "id_accounting_ledger_account"}),
        new HeaderGrid("Descripcion", "description"),
        new HeaderGrid("Centro de Costo", "cc", { type:"upper", formula: "search", expression: "find", data: [], data_key: "id", data_search_key: "id_accounting_cost_center", data_value: "name", 
        editable: { 
          component: "Dropdown",
          model: "id_accounting_cost_center",
          props: {
            filter: true,
            options: [],
            optionLabel: "name",
            optionValue: "id" 
          }
         }}),
        new HeaderGrid("Cantidad", "quantity", { type:"decimal", function: "SUM"  }),
        new HeaderGrid("Importe", "amount", { type:"currency", function: "SUM"  }),
        new HeaderGrid("Imp. Tras.", "taxes_transferred", { type:"currency", function: "SUM"  }),
        new HeaderGrid("Imp. Ret.", "taxes_detained", { type:"currency", function: "SUM"  }),
      ],
      supplierHeaders: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("RFC", "rfc"),
        new HeaderGrid("E-mail", "sale_email")
      ],
      invoiceHelperHeaders:[
                  new HeaderGrid('Factura', 'concat_invoice', {formula: 'concat', expression: 'series + {-} + id_invoice'}),
                  new HeaderGrid('Fecha', 'date', { type: 'date'} ),
                  new HeaderGrid('Emisor', 'issuing_name'),
                  new HeaderGrid('RFC Emisor', 'issuing_rfc'),
                  new HeaderGrid('Total', 'total', { type: 'currency'})
                ],
      entity: new DTPInvoice(),
      suppliers: [],
      loading: false,
      extrayendo: false,
      supplierNotCreatedModel: false,
      c_MetodoPago: [],
      c_FormaPago: [],
      c_TipoDeComprobante: [],
      c_Exportacion: [],
      c_Moneda: [],
      c_RegimenFiscal: [],
      c_UsoCFDI: [],
      c_ClaveUnidad: [],
      c_TipoRelacion: [],
      c_TipoRelacionCancelacion: [],
      payment_conditions: [],
      cost_centers: [],
      accounting_ledger_accounts: [],
      dtp_concepts: [],
      modalProveedor: false,
      loadingDatatable:false,
      validate: {
                valid: false,
                validations: {
                    series: null,
                    payment_form: null,
                    currency: null,
                    exchange_rate: null,
                    voucher_type: null,
                    export: null,
                    payment_method: null,
                    expedition_place: null,
                    issuing_rfc: null,
                    issuing_name: null,
                    issuing_tax_regime: null,
                    receiver_rfc: null,
                    receiver_name: null,
                    receiver_postal_code: null,
                    receiver_tax_regime: null,
                    receiver_invoice_use: null,
                    receiver_email: null,
                },
            },
    };
  },
  computed: {
    totalMXN() {
      return HEADER_TYPES.currency(this.entity.exchange_rate * this.entity.total);
    },
    loadingMessage() {
      return this.extrayendo ? "Extrayendo correos de buzon" : "Cargando";
    },
    status(){
      if (this.entity.status == 1) {
        return {
          severity: 'primary',
          status: 'Registrada',
          image: "processing.png"
        } 
      } else if (this.entity.status == 2) {
        return {
          severity: 'primary',
          status: 'Provisionada',
          image: "processing.png"
        } 
      }
      else if (this.entity.status == 3) {
        return {
          severity: 'primary',
          status: 'Pagada',
          image: "coin.svg"
        } 
      }
      else if (this.entity.status == 4) {
        return {
          severity: 'primary',
          status: 'Pagada Parcial',
          image: "coin.svg"
        } 
      }
      else if (this.entity.status == 9) {
        return {
          severity: 'primary',
          status: 'Cancelada',
          image: "cancel.png"
        } 
      } else {
        return {
          severity: 'primary',
          status: 'Registrada',
          image: "processing.png"
        } 
      }
    }
  },
  methods: {
    async updateCell(payload) {
      this.loadingDatatable = true;
      await sleep(2000);
      this.loadingDatatable = false;
      console.log("payload", payload);
    },
    async provision(){
      this.loading = true;
      try {

        if(!this.entity.id)
          this.$toast.add(new ErrorToast("Debe seleccionar una factura."));
        if(this.entity.id && this.entity.status > 1)
          this.$toast.add(new ErrorToast("La factura debe estar en estatus REGISTRADA para ser PROVISIONADA."));

        await this.entity.provision();
        this.entity.status = 2;
        this.$toast.add(
          new Toast({
            summary: "Factura Por Pagar",
            detail: "Factura provisionada con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async cancel_provision(){
      this.loading = true;
      try {

        if(!this.entity.id)
          this.$toast.add(new ErrorToast("Debe seleccionar una factura."));

        if(this.entity.id && this.entity.status != 2 )
          this.$toast.add(new ErrorToast("La factura debe estar en estatus PROVISIONADA para proceder con la cancelación."));

        await this.entity.cancel_provision();
        this.entity.status = 1;
        this.$toast.add(
          new Toast({
            summary: "Factura Por Pagar",
            detail: "Provision cancelada con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    openProveedor() {
      this.supplierNotCreatedModel = false;
      this.id_supplier = 0;
      this.id_editing = this.entity.id;
      this.invoice_issuing_entity = {
        name: this.entity.issuing_name,
        rfc: this.entity.issuing_rfc,
        email: this.entity.invoice_email
      }
      this.modalProveedor = true;
    },
    supplierSaved(supplier) {
      this.modalProveedor = false;
      this.selectSupplier(supplier);
      this.suppliers.push(supplier);
      this.invoices.forEach(x => x.id_supplier = x.issuing_rfc == supplier.rfc ? supplier.id : x.id_supplier);
      this.id_editing = null;
      this.id_supplier = null;
    },
    importedXML(res) {
      let invoice = JSON.parse(res.xhr.response);
      this.selectInvoice(invoice);
      this.invoices.push(invoice);
      this.toastSuccess("Importacion XML", "XML Cargado con exito");  
      this.supplierNotCreatedModel = this.entity.id_supplier == null;
    },
    selectSupplier(supplier) {
      this.entity.supplier_name = supplier.rfc + " - " + supplier.name;
      this.entity.id_supplier = supplier.id;
      this.entity.id_payment_condition = supplier.id_payment_condition;

    },
    async selectInvoice(invoice) {
      try {
        this.loading = true;
        invoice.date = invoice.date != null ? new Date(invoice.date) : null;
        invoice.expiration_date = invoice.expiration_date != null ? new Date(invoice.expiration_date) : null;
        invoice.reception_date = invoice.reception_date != null ? new Date(invoice.reception_date) : null;
        this.entity = fillObject(this.entity, invoice);
        console.log("Obj: " + JSON.stringify(this.entity))
        this.entity.invoice_name = this.entity.series + "-" + this.entity.id_invoice;
        this.entity.concepts = await this.entity.getConcepts();
        let supplier = this.suppliers.find(x => x.id == this.entity.id_supplier);
        if (supplier)
          this.selectSupplier(supplier);
      } catch (error) {
        console.log(error);
      } finally { 
        this.loading = false;
      }
    },
    async refresh() {
      this.entity = new DTPInvoice(this.session);
      this.loading = true;
      try {
        this.invoices = (await new DTPInvoice(this.session).all());
        if (this.id) {
          this.selectInvoice(this.invoices.find(x => x.id == this.id));
          this.invoices = [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.refresh();
    this.uploadURL = this.$config.api_route + "dtp_invoice/DTPInvoice/import/xml";
    this.c_MetodoPago = await satCatalogo(3);
    this.c_FormaPago = await satCatalogo(6);
    this.c_TipoDeComprobante = await satCatalogo(13);
    this.c_Exportacion = await satCatalogo(5);
    this.c_Moneda = await satCatalogo(9);
    this.suppliers = await new Supplier(this.session).all();
    this.payment_conditions = await new PaymentCondition(this.session).all();
    this.accounting_ledger_accounts = await new AccountingLedgerAccount(this.session).all();
    this.cost_centers = await new AccountingCostCenter(this.session).all();
    this.dtp_concepts = await new DTPConcept(this.session).all();
    this.conceptHeaders[0].data = this.dtp_concepts;
    this.conceptHeaders[2].data = this.accounting_ledger_accounts;
    this.taxesHeaders[4].data = this.accounting_ledger_accounts;
    this.conceptHeaders[4].editable.props.options = this.cost_centers;
    this.conceptHeaders[4].data = this.cost_centers;
  },
  components: { SupplierUI, FileIcon, FormInputNumber, FormDropdownComplex, Helper, BasicDatatable, FormCalendar,FormInputText,Loader,BasicFormToolbar, FormDropdown, Status},
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
